<template>
  <div class="foot">
    <div class="container">
      <div class="top">
        <ul class="option">
          <li
            v-for="(item, index) in about"
            :key="index"
            @click="goAbout(item.path)"
          >
            {{ item.name }}
          </li>
        </ul>
      
        <ul>
          <li style="color: #fff">联系我们</li>
          <li>电话：18681507063</li>
          <li>邮箱：sale1@cyber-elec.com</li>
          <li>地址：广东省深圳市龙华区布龙路元芬科技园B区B栋416-417室</li>
        </ul>
      </div>
      <div class="middle">
        <div id="map"></div>
      </div>
    </div>
    <div class="bottom">
      <div>
        © 2024 辰芸科技. All Rights Reserved. 备案号：
        <a href="https://beian.miit.gov.cn" target="_blank">
          粤ICP备2024310139号
        </a>
        <br/>版权所有：深圳市辰芸科技有限公司
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import AMapLoader from "@amap/amap-jsapi-loader";
export default {
  name: "Foot",
  setup() {
    const store = useStore();
    const info = reactive({
      // service1: [
      //   "电商平台开发",
      //   "人才外包",
      //   "企业软件定制",
      //   "企业微信定制",
      //   "RAP机器人",
      // ],
      // service2: ["手机端开发", "物联网开发", "大数据分析", "对日软件外包"],
      solutions1: [
        { name: "生物制药领域MES", path: "/solutions/pharmaceuticals" },
        { name: "数据中心", path: "/solutions/dataCenter" },
        { name: "物业管理APP", path: "/solutions/PropertyMag" },
        { name: "电商平台", path: "/solutions/eco_platform" },
        { name: "汽车导航", path: "/solutions/carNavigation" },
      ],
      solutions2: [
        { name: "外卖骑手小程序", path: "/solutions/TakeOutRider" },
        { name: "Erp项目", path: "/solutions/erp" },
        { name: "快信通项目", path: "/solutions/fastComProject" },
        { name: "服务管理系统", path: "/solutions/serviceManSystem" },
        { name: "用户管理系统", path: "/solutions/userManSystem" },
      ],
      about: computed(() => store.state.navList[2].submenu),
    });
    const router = useRouter();
    const goSolution = (path) => {
      router.push(path);
      window.scrollTo(0, 460);
    };
    const goAbout = (path) => {
      window.location.href = path;
    };
    const service = (path) => {
      window.location.href = path;
    };

    // const initMap = () => {
    //   AMapLoader.load({
    //     key: "4d3027dc95493bd92bcae1a48ccc0bac",
    //     version: "2.0",
    //     plugins: [
    //       "AMap.Scale", //工具条，控制地图的缩放、平移等
    //       "AMap.ToolBar", //比例尺，显示当前地图中心的比例尺
    //     ],
    //   })
    //     .then((AMap) => {
    //       let map = new AMap.Map("map", {
    //         //设置地图容器id
    //         zoom: 16, //初始化地图层级
    //         viewMode: "2D", //是否为3D地图模式
    //         center: [118.880055, 32.092134], //初始化地图中心点位置
    //       });
    //       map.addControl(new AMap.Scale());
    //       map.addControl(new AMap.ToolBar());
    //       map.add(
    //         new AMap.Marker({
    //           icon: require("@/assets/marker.png"),
    //           position: map.getCenter(),
    //           offset: new AMap.Pixel(-10, -15),
    //         })
    //       );
    //       let infoWindow = new AMap.InfoWindow({
    //         //创建信息窗体
    //         isCustom: true, //使用自定义窗体
    //         anchor: "bottom-center", //信息窗体的三角所在位置
    //         content: `<a href="https://uri.amap.com/marker?position=118.880055,32.092134&name=南京恒鼎锐网络科技有限公司" target="_blank" style="display:block;width:170px;text-align:center;line-height:25px;font-size:12px;color:#fff;background-color:#409eff;border-radius:5px;box-shadow:0 0 5px #409eff;user-select:none;cursor:pointer;">南京恒鼎锐网络科技有限公司<a/>`, //信息窗体的内容可以是任意html片段
    //         offset: new AMap.Pixel(0, -15),
    //       });
    //       infoWindow.open(map, [118.880055, 32.092134]); //填写想要窗体信息指示的坐标
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //     });
    // };
    onMounted(() => {
      // initMap();
    });
    return {
      ...toRefs(info),
      goSolution,
      goAbout,
      service,
      // initMap,
    };
  },
};
</script>
<style lang="less" scoped>
.foot {
  width: 100%;
  background: #191e28;
  color: #a6a6b0;
  padding: 43px 0 23px;
  a {
    color: #a6a6b0;
  }
  a:hover {
    color: #0099ff;
  }
  .container {
    width: 1300px;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
    .top {
      display: flex;
      ul {
        margin-right: 100px;
        li {
          margin-bottom: 10px;
        }
      }
      .option {
        li:hover {
          cursor: pointer;
          color: #0099ff;
        }
      }
    }
    #map {
      width: 400px;
      height: 230px;
      :deep(.amap-logo) {
        display: none !important;
      }
      :deep(.amap-copyright) {
        bottom: -100px;
        display: none !important;
      }
    }
  }
  .bottom {
    width: 100%;
    max-width: 1920px;
    margin-top: 50px;
    font-size: 12px;
    color: #a6a6b0;
    text-align: center;
  }
}
</style>
