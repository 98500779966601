import { createStore } from "vuex";

export default createStore({
  state: {
    navList: [
      { name: "首页", path: "/" },
      // { name: "服务", path: "/#provide" },
      {
        name: "产品",
        path: "/solutions",
        submenu: [
          { name: "笔记本电脑", path: "/solutions/enterprise" },
          { name: "一体机", path: "/solutions/campusfeedback" },
          { name: "迷你PC", path: "/solutions/pharmaceuticals" },
          { name: "智能手表", path: "/solutions/dataCenter" },
        ],
      },
      // { name: "IT人力外包", path: "/ITManpowerOutsourcing" },
      {
        name: "关于我们",
        path: "/about",
        submenu: [
          { name: "企业概况", path: "/about#Survey",label:'Survey' },
          { name: "核心价值", path: "/about#CoreValue",label:'CoreValue' },
          { name: "工厂风采", path: "/about#Style",label:'Style' },
          //{ name: "证书认证", path: "/about#Honor",label:'Honor' },
        ],
      },
    ],
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
