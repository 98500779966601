import { createApp } from "vue";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";

createApp(App).use(store).use(router).use(Antd).mount("#app");

router.afterEach((to, from, next) => {
  if (!to.fullPath.includes('about')) {
    window.scrollTo(0, 0);
  }
});
