<template>
  <div id="provide" class="container">
    <div class="title">
      <div class="font_zh">我们能为您提供什么？</div>
      <div class="font_en">WHAT CAN WE DO?</div>
    </div>
    <ul>
      <li
        v-for="(item, index) in currentList"
        :key="index"
        :class="{ active: currentIndex === index }"
        @click="currentIndex = index"
        @mouseover="currentIndex = index"
      >
        <div class="list_item">
          <img :src="item.bgUrl" alt="" />
          <div v-if="index == 0" class="service_left" @click.stop="goPrev">
            <img src="@/assets/Home/icon_left.png" />
          </div>
          <div v-if="index == 5" class="service_right" @click.stop="goNext">
            <img src="@/assets/Home/icon_right.png" />
          </div>
          <div class="desc">{{ item.title }}</div>
        </div>
        <div class="triangle">
          <caret-up-filled
            :style="{ fontSize: '40px', color: '#d4dae261' }"
            v-if="currentIndex === index"
          />
        </div>
      </li>
    </ul>
    <div class="content">
      <h2>{{ currentList[currentIndex].title }}</h2>
      <p v-html="currentList[currentIndex].content"></p>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import { CaretUpFilled } from '@ant-design/icons-vue';

export default {
  name: 'Provide',
  components: {
    CaretUpFilled
  },
  setup() {
    const state = reactive({
      list: [
        { title: '笔记本电脑', bgUrl: require('@/assets/Home/service1.png'), content: '最核心的产品，也是款式最多的产品' },
        { title: '迷你PC', bgUrl: require('@/assets/Home/service3.png'), content: ' 主打性价比最高的MINI PC主机，可自由定制' },
        { title: '手表', bgUrl: require('@/assets/Home/service4.png'), content: '性价比很高的电子手表，支持GPS，高度定制化方案' },
        { title: '手机产品配件', bgUrl: require('@/assets/Home/service5.png'), content: '快销品是最畅销的，涵盖了苹果安卓手机充电头，充电线，以及笔记本专用的电源适配器，还有便携式耳机' },

      ],
      // list2: [
      //   { title: '物联网开发', bgUrl: require('@/assets/Home/service7.png'), content: '通过主流的物联网协议通讯，在智能设备与云端之间建立安全的双向连接，快速实现各类物联网项目。同时可以提供各类物联网解决方案' },
      //   { title: '大数据分析', bgUrl: require('@/assets/Home/service8.png'), content: ' 为各种大中小型公司提供信息系统后期产品维护与升级服务、服务器管理及调优、网络与局域网的规划维护和组建、网络综合布线工程、企业监控网络建设、电脑及外设维修维护、网络技术支持及咨询，并提供7*24的全天候技术服务，费用透明公开，真正为企业做好信息化建设。' },
      //   { title: '对日软件外包', bgUrl: require('@/assets/Home/service9.png'), content: '鼎锐软件是一家专业的对日软件外包(离岸开发)服务公司，目前主要承接对日JAVA、.NET（C#，VB.NET）、COBOL、PHP、 H5、C++、IOS、Android等语言类型的项目。目前在金融，物流，证券，电商，汽车等行业都取得了骄人的业绩。目前寻求国内，日本的合作伙伴进行更深入的合作。<br />地址： <br />苏州鼎锐软件科技有限公司 <br />地址：苏州工业园区金鸡湖大道1355号&nbsp;国际科技园一期204A-1 <br />电话：0512-65984482 <br />邮件：groupzhaopin@dingruiit.com<br />泰州腾翔信息科技有限公司（苏州鼎锐分公司）<br />地址：江苏省泰州市海陵工业园区青年北路26号科技大厦6楼<br />电话：0523-86083978 <br />邮件：groupzhaopin@dingruiit.com<br />' }
      // ],
      currentIndex: 0,
      currentList: []
    })

    state.currentList = state.list

    const goNext = () => {
      state.currentIndex = 0
      state.currentList=state.list2
    }
    const goPrev = () => {
      state.currentIndex = 0
      state.currentList=state.list
    }

    return {
      ...toRefs(state),
      goPrev,
      goNext
    }
  }
}
</script>
<style lang="less" scoped>
.container {
  width: 1300px;
  margin: 54px auto;
}
.title {
  text-align: center;
  .font_en {
    height: 25px;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #e9e9e9;
    line-height: 25px;
  }
  .font_zh {
    height: 42px;
    font-size: 30px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ff5e5e;
    line-height: 42px;
  }
}

ul {
  display: flex;
  margin-top: 80px; //轮播顶部
  margin-bottom: 0px;
  padding: 0;

  li {
    position: relative;
    margin-right: 20px;
    & > .list_item {
      position: relative;
      width: 200px;
      height: 200px;
      background: #f6f6f6;
      border-radius: 14px;
      box-sizing: border-box;
      img {
        width: 200px;
        height: 200px;
      }
    }

    .desc {
      width: 100%;
      height: 50px;
      line-height: 50px;
      text-align: center;
      background: rgba(239, 4, 0, 0.27);
      position: absolute;
      bottom: 0;
      left: 0;
      font-size: 18px;
      font-weight: 600;
      color: #ffffff;
      display: none;
    }
    .service_left,
    .service_right {
      position: absolute;
      top: 50%;
      margin-top: -25px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      background: #000000;
      opacity: 0.1;
      border-radius: 50%;
      &:hover {
        opacity: 0.5;
        cursor: pointer;
      }
      img {
        width: 25px;
        height: 25px;
      }
    }

    .service_left {
      left: 10px;
    }
    .service_right {
      right: 10px;
    }

    .triangle {
      position: relative;
      height: 20px;
      .anticon {
        position: absolute;
        bottom: -12px;
        left: 50%;
        margin-left: -20px;
      }
    }
    &:hover {
      cursor: pointer;
    }
  }
  li.active {
    .desc {
      display: block;
    }
  }
}
.content {
  position: relative;
  // height: 368px;
  background: rgba(212, 218, 226, 0.38);
  box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.09);
  width: 100%;
  padding: 50px 100px;

  h2 {
    font-size: 24px;
    font-weight: 500;
    color: #333333;
    text-align: left;
  }
  p {
    font-size: 14px;
    color: #a4a4a4;
    line-height: 24px;
    text-align: left;
  }
}
</style>