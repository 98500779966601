<template>
  <div class="about_container">
    <div class="title">
      <div class="font_en">ABOUT US</div>
      <div class="font_zh">关于我们</div>
    </div>
    <div class="content">
      <p>
        深圳市辰芸科技有限公司，成立于2023年3月份。自成立以来，辰芸科技始终秉承“最优质的产品和服务”的经营理念，致力于为全球客户提供电子产品和服务。公司拥有一支经验丰富的专业销售团队，以及研发工程团队，致力于笔记本电脑的自有研发与生产销售一体化。目前，公司主要经营笔电，一体机，迷你PC，手表等电子产品的销售，产品远销中亚，俄罗斯，北美地区。公司与业内多家知名品牌的供应链公司建立了稳定持久的合作关系。
          未来，辰芸科技将继续秉承提供最好的产品和服务的愿景，不断创新，为客户创造更大的价值。
      </p>
      <img src="@/assets/Home/home_about.png" alt="" />
    </div>
  </div>
</template>
<script>
import { reactive, toRefs } from "vue";

export default {
  name: "PracticeCertification",
  setup() {
    const state = reactive({});

    return {
      ...toRefs(state),
    };
  },
};
</script>
<style lang="less" scoped>
.about_container {
  width: 100%;
  margin-top: 50px;

  .title {
    text-align: center;
    .font_en {
      font-size: 16px;
      font-weight: 600;
      color: #e9e9e9;
      line-height: 16px;
    }
    .font_zh {
      font-size: 21px;
      font-weight: 600;
      color: #333;
      line-height: 20px;
    }
  }
  .content {
    padding: 10px;
    margin-top: 20px;
    p {
      width: 100%;
      font-size: 13px;
      color: #404040;
      margin-bottom: 10px;
    }
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}
</style>
