<template>
  <div id="provide" class="container">
    <div class="title">
      <div class="font_en">WHAT CAN WE DO?</div>
      <div class="font_zh">我们能为您提供什么？</div>
    </div>
    <a-carousel>
      <ul class="list" v-for="(item, index) in list" :key="index">
        <li
          v-for="(child, i) in item"
          :key="i"
          @click="toProvideDetail(child.id)"
        >
          <div>
            <div class="img-box">
              <img :src="child.bgUrl" alt="" />
            </div>
            <div class="item-title">{{ child.title }}</div>
          </div>
        </li>
      </ul>
    </a-carousel>
  </div>
</template>
<script>
import { reactive, toRefs } from "vue";
import { CaretUpFilled } from "@ant-design/icons-vue";
import { useRouter } from "vue-router";

export default {
  name: "Provide",
  components: {
    CaretUpFilled,
  },
  setup() {
    const state = reactive({
      list: [
        [
        { 
          id: 1,
          title: '笔记本电脑',
          bgUrl: require('@/assets/Home/service1.png'), 
         content: '最核心的产品，也是款式最多的产品' 
        },
        { 
          id: 2,
          title: '迷你PC', 
          bgUrl: require('@/assets/Home/service3.png'), 
          content: ' 主打性价比最高的MINI PC主机，可自由定制' 
        },
        { 
          id: 3,
          title: '手表', 
          bgUrl: require('@/assets/Home/service4.png'), 
          content: '性价比很高的电子手表，支持GPS，高度定制化方案' 
        },
          
        ],
        [
        { 
          id: 4,
          title: '手机产品配件', 
          bgUrl: require('@/assets/Home/service5.png'), 
          content: '快销品是最畅销的，涵盖了苹果安卓手机充电头，充电线，以及笔记本专用的电源适配器，还有便携式耳机'
         },
        ],
      ],
    });
    const router = useRouter();
    const toProvideDetail = (id) => {
      // todo 暂时注释的代码
      router.push({
        path: "/provideDetail",
        query: {
          id: id,
        },
      });
    };
    return {
      ...toRefs(state),
      toProvideDetail,
    };
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 100%;
  margin: 30px auto;
}
.title {
  text-align: center;
  .font_en {
    font-size: 16px;
    font-weight: 600;
    color: #e9e9e9;
    line-height: 16px;
  }
  .font_zh {
    font-size: 21px;
    font-weight: 600;
    color: #2083ee;
    line-height: 20px;
  }
}
.list {
  display: flex !important;
  padding: 20px 5px 0;
  li {
    width: calc((100vw - 40px) / 3);
    margin: 10px 5px;
    & > div {
      .img-box {
        width: calc((100vw - 40px) / 3);
        height: calc((100vw - 40px) / 3);
        border-radius: 14px;
        background: #f6f6f6;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 70px;
          height: 70px;
        }
      }
      .item-title {
        color: #333;
        text-align: center;
        font-size: 14px;
        margin-top: 10px;
      }
    }
  }
}
.ant-carousel :deep(.slick-dots) {
  bottom: 0;
}
.ant-carousel :deep(.slick-dots li button) {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #d8d8d8;
}
.ant-carousel :deep(.slick-dots li.slick-active button) {
  background: #2083ee;
}
</style>
