<template>
  <div class="home">
    <div class="banner">
      <!-- 轮播图 -->
      <a-carousel ref="carousel_index" autoplay :dots="false">
        <img src="@/assets/Home/index_l1.jpg" alt="" />
        <img src="@/assets/Home/index_l2.jpg" alt="" />
        <img src="@/assets/Home/index_l3.jpg" alt="" />
      </a-carousel>
      <div class="arrow_left">
        <img
          src="@/assets/Home/icon_left.png"
          @click="
            () => {
              this.$refs.carousel_index.prev();
            }
          "
        />
      </div>
      <div class="arrow_right">
        <img
          src="@/assets/Home/icon_right.png"
          @click="
            () => {
              this.$refs.carousel_index.next();
            }
          "
        />
      </div>
    </div>

    <a-carousel>
      <ul class="tabList" v-for="(item, index) in tabList" :key="index">
        <li v-for="(child, i) in item" :key="i">
          <div>
            <div style="display: flex; align-items: center; margin-bottom: 5px">
              <img :src="child.icon" alt="" />
              <span style="margin-left: 5px; color: #333333; font-size: 14px">
                {{ child.title }}
              </span>
            </div>
            <div style="color: #666666; font-size: 12px">{{ child.desc }}</div>
          </div>
        </li>
      </ul>
    </a-carousel>

    <Provide />
    <Solution />
    <ITManpowerOutsoucing />
    <PracticeCertification />
    <News />
    <About />
    <Partner />
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import Provide from "@/views/H5/Home/components/Provide.vue";
// import Solution from "@/views/H5/Home/components/Solution.vue";
// import ITManpowerOutsoucing from "@/views/H5/Home/components/ITManpowerOutsoucing.vue";
//import PracticeCertification from "@/views/H5/Home/components/PracticeCertification.vue";
//import News from "@/views/H5/Home/components/News.vue";
import About from "@/views/H5/Home/components/About.vue";
//import Partner from "@/views/H5/Home/components/Partner.vue";
export default {
  name: "HomeView",
  components: {
    Provide,
    // Solution,
    // ITManpowerOutsoucing,
    // PracticeCertification,
    // News,
    About,
    // Partner,
  },
  setup() {
    // const state = reactive({
    //   tabList: [
    //     [
    //       {
    //         title: "服务面广",
    //         icon: require("@/assets/Home/icon_main_point1_1.png"),
    //         desc: "服务领域涵盖范围广，涉及制造业、金融业、建筑业、医疗业等",
    //       },
    //       {
    //         title: "技术全面",
    //         icon: require("@/assets/Home/icon_main_point2_1.png"),
    //         desc: "精通主流开发技术，专业技术人员超过80",
    //       },
    //       {
    //         title: "服务稳定",
    //         icon: require("@/assets/Home/icon_main_point3_1.png"),
    //         desc: "专业售后团队提供365天7*24小时不间断服务",
    //       },
    //     ],
    //     [
    //       {
    //         title: "经验丰富",
    //         icon: require("@/assets/Home/icon_main_point4_1.png"),
    //         desc: "为企业提供最新最全面的it优化解决方案",
    //       },
    //       {
    //         title: "开源节 流",
    //         icon: require("@/assets/Home/icon_main_point5_1.png"),
    //         desc: "减少您的IT开支，并获取及时全面的支持服务",
    //       },
    //     ],
    //   ],
    // });
    // return {
    //   ...toRefs(state),
    // };
  },
};
</script>

<style lang="less" scoped>
.home {
  padding-bottom: 85px;
  margin-top: 80px;
}
.banner {
  position: relative;
  width: 100%;
  // height: 740px;

  img {
    width: 100%;
    height: 100%;
  }
  .banner-bg {
    width: 100%;
    height: 160px;
  }
  .arrow_left,
  .arrow_right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #000000;
    opacity: 0.1;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      opacity: 0.5;
    }
    img {
      width: 20px;
      height: 20px;
    }
  }

  .arrow_left {
    left: 20px;
  }

  .arrow_right {
    right: 20px;
  }

  .arrow_left:hover,
  .arrow_right:hover {
    cursor: pointer;
  }

  /deep/.ant-carousel .slick-slide img {
    display: inline-block;
  }
  .bannerInfo {
    position: absolute;
    top: 30px;
    left: 15%;
    h1 {
      font-size: 12px;
      font-weight: 500;
      line-height: 50px;
      letter-spacing: 5px;
      color: #fff;
    }
    p {
      font-size: 10px;
      color: #ffffff;
      line-height: 25px;
      opacity: 60%;
      text-align: left;
    }
    .button {
      width: 181px;
      height: 60px;
      border-radius: 9px;
      border: 1px solid #ffffff;
      color: #fff;
      font-size: 25px;
      line-height: 60px;
      text-align: center;
      letter-spacing: 4px;
      margin-top: 72px;
    }
    .button:hover {
      cursor: pointer;
    }
  }
}
.tabList {
  padding: 10px 5px;
  display: flex !important;
  li {
    width: calc((100vw - 40px) / 3);
    padding: 10px;
    margin: 0 5px;
    border: 1px solid #f0f0f0;
    border-radius: 10px;
    img {
      width: 22px;
      height: 22px;
    }
  }
}
.ant-carousel :deep(.slick-dots) {
  bottom: 0;
}
.ant-carousel :deep(.slick-dots li button) {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #d8d8d8;
}
.ant-carousel :deep(.slick-dots li.slick-active button) {
  background: #2083ee;
}
</style>
