<template>
  <!-- 导航栏 -->
  <div class="navbar">
    <img class="logo" src="../assets/logo.png" />
    <div class="nav_btn_top" v-if="!isShowMenu">
      <img src="@/assets/Nav/menu.png" @click="changeMenu" />
    </div>
    <div :class="isShowMenu ? 'nav_box show' : 'nav_box hide'">
      <div class="nav_btn">
        <img
          v-if="isShowMenu"
          src="@/assets/Nav/close.png"
          @click="changeMenu"
        />
      </div>
      <ul class="nav">
        <li
          v-for="(item, index) in navList"
          :key="index"
          @click="goPath(item.path)"
        >
          {{ item.name }}
        </li>
        <li><img src="@/assets/icon_phone.png" />18681507063</li>
      </ul>
    </div>
  </div>
</template>
<script>
import { computed, reactive, toRefs, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: "Nav",
  setup() {
    const store = useStore();
    const navList = computed(() => store.state.navList);
    const state = reactive({
      isShowMenu: false,
    });

    const router = useRouter();
    const goPath = (path) => {
      router.push(path);
      state.isShowMenu = false;
    };
    const changeMenu = () => {
      state.isShowMenu = !state.isShowMenu;
    };

    onMounted(() => {});

    return {
      navList,
      ...toRefs(state),
      goPath,
      changeMenu,
    };
  },
};
</script>
<style lang="less" scoped>
.navbar {
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 99;
  background: #ffffff;
  .logo {
    width: 146px;
    height: 40px;
  }
  .nav_btn_top {
    position: absolute;
    top: 0;
    right: 20px;
    height: 80px;
    line-height: 80px;
    img {
      width: 24px;
      height: 24px;
    }
  }
  .nav_box {
    position: fixed;
    top: 0;
    // right: 0;
    width: 200px;
    height: 100vh;
    background: #fff;
    box-shadow: 0 0 10px #ccc;
    transition: all 0.5s;
    .nav_btn {
      width: 100%;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 20px;
      img {
        width: 24px;
        height: 24px;
      }
    }
    .nav {
      li {
        padding: 20px 30px;
        font-size: 15px;
        font-weight: bold;
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  .show {
    right: 0;
  }
  .hide {
    right: -220px;
  }
}
</style>
