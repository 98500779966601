<template>
  <div>
    <NavH5 v-if="isH5" />
    <Nav v-else />
    <router-view />
    <FootH5 v-if="isH5" />
    <Foot v-else />
  </div>
</template>
<script>
import Nav from "./components/Nav.vue";
import Foot from "./components/Foot.vue";
import NavH5 from "./components/NavH5.vue";
import FootH5 from "./components/FootH5.vue";
import { onMounted, reactive, toRefs } from "vue";
export default {
  components: {
    Nav,
    Foot,
    NavH5,
    FootH5,
  },
  setup() {
    const state = reactive({
      isH5: false,
    });
    onMounted(() => {
      const screenWidth = document.documentElement.clientWidth;
      state.isH5 = screenWidth && screenWidth > 960 ? false : true;
    });
    return {
      ...toRefs(state),
    };
  },
};
</script>
<style lang="less">
#app {
  font-family: PingFangSC-Regular, PingFang SC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
ul {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
