<template>
  <div class="about_container">
    <div class="title">
      <div class="font_zh">关于我们</div>
      <div class="font_en">ABOUT US</div>
    </div>
    <div class="content">
      <p>
        公司成立于2023年3月份。自成立以来，辰芸科技始终秉承“最优质的产品和服务”的经营理念，致力于为全球客户提供电子产品和服务。公司拥有一支经验丰富的专业销售团队，以及研发工程团队，致力于笔记本电脑的自有研发与生产销售一体化。
        <br/>目前，公司主要经营笔电，一体机，迷你PC，手表等电子产品的销售，产品远销中亚，俄罗斯，北美地区。公司与业内多家知名品牌的供应链公司建立了稳定持久的合作关系。
        <br/>未来，辰芸科技将继续秉承提供最好的产品和服务的愿景，不断创新，为客户创造更大的价值。
      </p>
      <img src="@/assets/Home/home_about.png" alt="" />
    </div>
  </div>
</template>
<script>
import { reactive, toRefs } from 'vue'

export default {
  name: 'PracticeCertification',
  setup() {
    const state = reactive({
    })

    return {
      ...toRefs(state),
    }
  }
}
</script>
<style lang="less" scoped>
.about_container {
  width: 1300px;
  margin: 70px auto;

  .title {
    text-align: center;
    width: 238px;
    .font_en {
      height: 25px;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #e9e9e9;
      line-height: 25px;
      margin-top: 9px;
    }
    .font_zh {
      height: 55px;
      font-size: 34px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #404040;
      line-height: 48px;
      background: url("@/assets/title_bg.png") no-repeat;
      background-size: 238px 22px;
      background-position: 0px 27px;
    }
  }
  .content {
    margin-top: 26px;
    display: flex;
    justify-content: space-between;
    p {
      width: 609px;
      height: 243px;
      font-size: 15px;
      color: #404040;
      line-height: 27px;
      margin: 0;
    }
    img {
      width: 558px;
      height: 243px;
    }
  }
}
</style>